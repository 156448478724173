import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { routePaths } from 'projects/web/src/app/route-paths';

import { PrivacyPolicyDialogComponent } from '../privacy-policy-dialog/privacy-policy-dialog.component';
import { CookiePolicyDialogComponent } from '../cookie-policy-dialog/cookie-policy-dialog.component';

/** Footer component. */
@Component({
	selector: 'scriptaw-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class FooterComponent {
	private readonly dialog = inject(MatDialog);

	/** Current year. */
	public readonly currentYear = new Date().getFullYear();

	/** Router paths. */
	public readonly routerPaths = routePaths;

	/** Opens privacy policy dialog. */
	public openPrivacyPolicyDialog(): void {
		this.dialog.open<PrivacyPolicyDialogComponent, never>(
			PrivacyPolicyDialogComponent,
			{
				width: '1024px',
			},
		);
	}

	/** Opens cookie policy dialog. */
	public openCookiePolicyDialog(): void {
		this.dialog.open<CookiePolicyDialogComponent, never>(
			CookiePolicyDialogComponent,
			{
				width: '1024px',
			},
		);
	}
}
