<div class="footer-container">
	<img
		src="assets/scripta-color.svg"
		class="logo"
		alt="logo"
	/>
	<span>
		Copyright © {{ currentYear }} Scripta - All Rights Reserved.
		<button
			class="link"
			type="button"
			(click)="openPrivacyPolicyDialog()"
		>
			Privacy Policy
		</button>
		<button
			class="link"
			type="button"
			(click)="openCookiePolicyDialog()"
		>
			Cookie Policy
		</button>
	</span>
	<span>
		Scripta is not a law firm. All information provided via Scripta’s subscription services is for informational and
		educational purposes only and does not constitute legal advice.
	</span>
</div>
